import Text from '@components/atoms/Text';
import Title from '@components/atoms/Title';
import useAsyncStore from '@hooks/store/useAsyncStore';
import useBinRetrievalCost from '@hooks/useBinRetrievalCost';

import { BIN_CAPACITY_MAP } from '../../../../constants';
import useCurrencyStore from '../../../../hooks/store/useCurrencyStore';
import { formatNumber, toPrice } from '../../../../utils/numberUtils';
import Button from '../../../Button';
import { ComparisonUnit, ComparisonUnitOptions } from '.';
import styles from './styles.module.scss';

const getComparisonValue = (comparisonUnit: ComparisonUnit, binCount: number) =>
  formatNumber(BIN_CAPACITY_MAP[comparisonUnit] * binCount, 0);

const getComparisonLabel = (comparisonUnit: ComparisonUnit) =>
  ComparisonUnitOptions.find(option => option.value === comparisonUnit)
    ?.cardLabel;

interface PricingCardProps {
  sizeLabelMeters: string;
  sizeLabelFeet: string;
  binCount: number;
  comparisonUnit: ComparisonUnit;
  upfrontCost?: number;
  savings: number;
  showPerPickCost?: boolean;
  children?: React.ReactNode;
}
const PricingCard: React.FC<PricingCardProps> = ({
  sizeLabelMeters,
  sizeLabelFeet,
  binCount,
  comparisonUnit,
  upfrontCost,
  showPerPickCost,
  children,
  savings
}) => {
  const currency = useAsyncStore(useCurrencyStore, state => state.currency);
  const perPickCost = useBinRetrievalCost();

  return (
    <div className={styles.priceCard}>
      <div className={styles.sizeLine}>
        <Title as="h3" variant="h1" color="navy">
          {sizeLabelFeet}
        </Title>
        <Text as="span" variant="label" color="navy">
          {sizeLabelMeters}
        </Text>
      </div>
      <div>
        <Text as="div" variant="label" color="grey">
          {'WAREHOUSE SPACE'}
        </Text>
      </div>
      <div className={styles.mediaWrap}>
        <figure className={styles.figure}>{children}</figure>
      </div>
      <div className={styles.bestFitWrap}>
        <Text
          className={styles.bestFitParagraph}
          as="p"
          variant="b2"
          color="grey5"
        >
          {`${binCount} bins store ${getComparisonValue(
            comparisonUnit,
            binCount
          )} ${getComparisonLabel(comparisonUnit)}`}
        </Text>
      </div>
      {showPerPickCost && (
        <div className={styles.perBinWrap}>
          <Text as="div" variant="label" color="navy">
            Costs per bin retrieval
          </Text>
          <Text as="div" variant="b1" color="navy">
            {/* This is a hack because they have hardcoded the usd value for the customer */}
            {currency === 'EUR' ? toPrice(perPickCost, 2) : '$0.60'}
          </Text>
        </div>
      )}

      {upfrontCost && (
        <div className={styles.costWrap}>
          <Text as="div" variant="label" color="navy">
            Estimated price
          </Text>
          <Text as="div" variant="b1" color="navy">
            {toPrice(upfrontCost, 0, 1000, currency)}
          </Text>
        </div>
      )}

      <div className={styles.savingsWrap}>
        <Text as="div" variant="label" color="navy">
          Estimated savings per year
        </Text>
        <Text
          as="div"
          variant="b1"
          color="navy"
          className={styles.savingsLabel}
        >
          {toPrice(savings, 0, 1000, currency)}
        </Text>
      </div>

      <Button
        className={styles.contactButton}
        href="get-started"
        variant="smallMain"
      >
        Design your warehouse
      </Button>
    </div>
  );
};

export default PricingCard;
