import { Column, Columns } from '@components/website/Columns';
import Image from 'next/legacy/image';
import { useState } from 'react';

import SectionWrap from '../ModuleSection';
import PricingCard from './PricingCard';
import styles from './styles.module.scss';
import TabList from './TabList';

export type ComparisonUnit = 'phones' | 'shirts' | 'sneakers';

type ComparisonUnitOption = {
  value: ComparisonUnit;
  label: string;
  cardLabel: string;
};

export const ComparisonUnitOptions: ComparisonUnitOption[] = [
  { value: 'shirts', label: 'T-Shirts', cardLabel: 'T-shirts' },
  { value: 'sneakers', label: 'Shoes', cardLabel: 'pairs of shoes' },
  { value: 'phones', label: 'Phones', cardLabel: 'phones' }
];

const ModulePricingTiers = () => {
  const [comparisonUnit, setComparisonUnit] =
    useState<ComparisonUnit>('shirts');
  return (
    <SectionWrap className={styles.sectionWrap}>
      <Columns className={styles.columnPadding}>
        <Column start={1} span={12}>
          {/* this is where the pricing tiers component goes*/}
          <div className={styles.selectorsWrap}>
            <TabList
              options={ComparisonUnitOptions}
              currentOption={comparisonUnit}
              setCurrentOption={(value: ComparisonUnit) =>
                setComparisonUnit(value)
              }
            />
          </div>
        </Column>
      </Columns>
      <Columns className={styles.columnPadding}>
        <Column start={1} span={4}>
          <PricingCard
            sizeLabelMeters={'(65m2)'}
            sizeLabelFeet={'700Ft2'}
            binCount={1200}
            comparisonUnit={comparisonUnit}
            savings={110091}
          >
            <Image
              src="/static/images/pio_pricing_125m2.png"
              objectFit="cover"
              layout="fill"
              alt="Pio System 3d Model 65m2"
            />
          </PricingCard>
        </Column>
        <Column start={5} span={4}>
          <PricingCard
            sizeLabelMeters={'(130m2)'}
            sizeLabelFeet={'1400ft2'}
            binCount={3600}
            comparisonUnit={comparisonUnit}
            savings={233027}
          >
            <Image
              src="/static/images/pio_pricing_60m2.png"
              objectFit="cover"
              layout="fill"
              alt="Pio System 3d Model 130m2"
            />
          </PricingCard>
        </Column>
        <Column start={9} span={4}>
          <PricingCard
            sizeLabelMeters={'(260m2)'}
            sizeLabelFeet={'2800ft2'}
            binCount={8500}
            comparisonUnit={comparisonUnit}
            savings={421100}
          >
            <Image
              src="/static/images/pio_pricing_250m2.png"
              objectFit="cover"
              layout="fill"
              alt="Pio System 3d Model 260m2"
            />
          </PricingCard>
        </Column>
      </Columns>
    </SectionWrap>
  );
};

export default ModulePricingTiers;
