import Button from '../../../Button';
import styles from './styles.module.scss';

interface TabListProps<OptionType> {
  options: ReadonlyArray<{
    value: OptionType;
    label: string;
    cardLabel: string;
  }>;
  currentOption: OptionType;
  setCurrentOption: (value: OptionType) => void;
}

const TabList = <OptionType,>({
  options,
  currentOption,
  setCurrentOption
}: TabListProps<OptionType>) => {
  return (
    <ul className={styles.tablist} role="tablist">
      {options.map(option => {
        const isSelected = option.value == currentOption;
        return (
          <li key={`${option.value}`} role="presentation">
            <Button
              role="tab"
              variant="subnav"
              aria-selected={isSelected}
              tabIndex={isSelected ? 0 : -1}
              onClick={() => setCurrentOption(option.value as OptionType)}
              isActive={isSelected}
              aria-label={`Toggle the ${option.label} tab`}
            >
              {option.label}
            </Button>
          </li>
        );
      })}
    </ul>
  );
};

export default TabList;
